import { inject, Injectable } from '@angular/core';
import { CheckPermissionsVm } from '../domain/view-models/check-permissions.vm';
import { Permission } from '../domain/entities/user.entity';
import { CurrentUserStatePort } from '../domain/ports/current-user.state.port';

export class CheckPermissionsRequest {
  constructor(
    public readonly permissions: Permission[],
    public readonly mode: 'all' | 'any' = 'any',
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class CheckPermissionsUc {
  private readonly state = inject(CurrentUserStatePort);

  execute(request: CheckPermissionsRequest): CheckPermissionsVm {
    const user = this.state.getCurrentUser();

    if (!user) {
      return new CheckPermissionsVm(false);
    }

    return new CheckPermissionsVm(user.checkPermissions(request.permissions, request.mode));
  }
}
