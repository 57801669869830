import { Injectable } from '@angular/core';
import { SignInVm } from '../../domain/view-models/sign-in.vm';
import { SignInResponse } from '../../use-cases/sign-in.uc';

@Injectable({
  providedIn: 'root',
})
export class SignInPresenter {
  present(response: SignInResponse): SignInVm {
    return new SignInVm(response.success, response.error);
  }
}
