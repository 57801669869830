import { inject, Injectable } from '@angular/core';
import { UsersRepositoryPort } from '../domain/ports/users.repository.port';

export class SetPasswordRequest {
  constructor(public readonly password: string) {}
}

@Injectable({
  providedIn: 'root',
})
export class SetPasswordUc {
  private readonly usersRepository = inject(UsersRepositoryPort);

  async execute(request: SetPasswordRequest): Promise<void> {
    await this.usersRepository.setNewPassword(request.password);
  }
}
