import { Injectable } from '@angular/core';
import { SignUpVm } from '../../domain/view-models/sign-up.vm';
import { SignUpResponse } from '../../use-cases/sign-up.uc';

@Injectable({
  providedIn: 'root',
})
export class SignUpPresenter {
  present(response: SignUpResponse): SignUpVm {
    return new SignUpVm(response.success, response.error);
  }
}
