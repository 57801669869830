import { inject, Injectable } from '@angular/core';
import { SecurityChallengesRepositoryPort } from '../domain/ports/security-challenges.repository.port';

export class SubmitSecurityChallengeRequest {
  constructor(
    public readonly challengeId: string,
    public readonly type: 'one-time-password',
    public readonly submittal: string,
  ) {}
}

export class SubmitSecurityChallengeResponse {
  constructor(public readonly grant: 'reset_password' | null) {}
}

@Injectable({
  providedIn: 'root',
})
export class SubmitSecurityChallengeUc {
  private readonly challengesRepository = inject(SecurityChallengesRepositoryPort);

  async execute(request: SubmitSecurityChallengeRequest): Promise<SubmitSecurityChallengeResponse> {
    const { challengeId, type, submittal } = request;
    const result = await this.challengesRepository.submitChallenge(challengeId, type, submittal);

    return new SubmitSecurityChallengeResponse(result.grant);
  }
}
