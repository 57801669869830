import { inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityChallengeChannel, SecurityChallengeScope } from '../../domain/entities/security-challenge.entity';
import { Permission, UserProfile } from '../../domain/entities/user.entity';
import { CurrentUserVm } from '../../domain/view-models/current-user.vm';
import { SecurityChallengeVm } from '../../domain/view-models/security-challenge.vm';
import { SignInVm } from '../../domain/view-models/sign-in.vm';
import { SignUpVm } from '../../domain/view-models/sign-up.vm';
import { SignInPresenter } from '../presenters/sign-in.presenter';
import { SignUpPresenter } from '../presenters/sign-up.presenter';
import { CheckPermissionsUc, CheckPermissionsRequest } from '../../use-cases/check-permissions.uc';
import { FetchCurrentUserUc } from '../../use-cases/fetch-current-user.uc';
import { GetCurrentUserUc } from '../../use-cases/get-current-user.uc';
import { SaveUserProfileUc, SaveUserProfileRequest } from '../../use-cases/save-current-user-profile.uc';
import { SetPasswordUc, SetPasswordRequest } from '../../use-cases/set-password.uc';
import { SignInUc, SignInRequest } from '../../use-cases/sign-in.uc';
import { SignOutUc } from '../../use-cases/sign-out.uc';
import { SignUpUc, SignUpRequest } from '../../use-cases/sign-up.uc';
import {
  SubmitSecurityChallengeUc,
  SubmitSecurityChallengeRequest,
} from '../../use-cases/submit-security-challenge.uc';
import { TakeSecurityChallengeUc, TakeSecurityChallengeRequest } from '../../use-cases/take-security-challenge.uc';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserController {
  grants = signal<string[]>([]);

  private readonly router = inject(Router);

  private readonly signInUc = inject(SignInUc);
  private readonly signUpUc = inject(SignUpUc);
  private readonly signOutUc = inject(SignOutUc);
  private readonly fetchCurrentUserUc = inject(FetchCurrentUserUc);
  private readonly getCurrentUserUc = inject(GetCurrentUserUc);
  private readonly takeChallengeUc = inject(TakeSecurityChallengeUc);
  private readonly submitChallengeUc = inject(SubmitSecurityChallengeUc);
  private readonly saveCurrentUserProfileUc = inject(SaveUserProfileUc);
  private readonly setPasswordUc = inject(SetPasswordUc);
  private readonly checkPermissionsUc = inject(CheckPermissionsUc);

  private signInPresenter = inject(SignInPresenter);
  private signUpPresenter = inject(SignUpPresenter);

  async signIn(email: string, password: string): Promise<SignInVm> {
    const request = new SignInRequest(email, password);
    const response = await this.signInUc.execute(request);

    return this.signInPresenter.present(response);
  }

  async signUp(email: string, password: string): Promise<SignUpVm> {
    const request = new SignUpRequest(email, password);
    const response = await this.signUpUc.execute(request);
    return this.signUpPresenter.present(response);
  }

  async signOut(): Promise<void> {
    await this.signOutUc.execute();
    this.router.navigate(['.']);
  }

  async fetchCurrentUser(): Promise<void> {
    await this.fetchCurrentUserUc.execute();
  }

  getCurrentUser(): CurrentUserVm | null {
    const response = this.getCurrentUserUc.execute();
    return response.present();
  }

  checkPermissions(permissions: Permission[]): boolean {
    return this.checkPermissionsUc.execute(new CheckPermissionsRequest(permissions, 'any')).isAllowed;
  }

  async saveCurrentUserProfile(profile: UserProfile): Promise<void> {
    await this.saveCurrentUserProfileUc.execute(new SaveUserProfileRequest(profile));
  }

  async setNewPassword(newPassword: string): Promise<void> {
    await this.setPasswordUc.execute(new SetPasswordRequest(newPassword));
    await this.fetchCurrentUser();
  }

  async takeChallenge(
    scope: SecurityChallengeScope,
    channel: SecurityChallengeChannel,
    channelIdentifier: string,
  ): Promise<SecurityChallengeVm> {
    const response = await this.takeChallengeUc.execute(
      new TakeSecurityChallengeRequest(scope, channel, channelIdentifier),
    );

    return response.toChallengeVm();
  }

  async submitChallenge(challengeId: string, code: string): Promise<void> {
    const { grant } = await this.submitChallengeUc.execute(
      new SubmitSecurityChallengeRequest(challengeId, 'one-time-password', code),
    );

    this.addGrant(grant);
  }

  private addGrant(grant: string | null) {
    if (!grant) {
      return;
    }

    this.grants.set([...this.grants(), grant]);
  }

  checkGrant(grant: 'reset_password'): boolean {
    return this.grants().includes(grant);
  }

  removeGrant(grant: string) {
    this.grants.set(this.grants().filter((g) => g !== grant));
  }
}
