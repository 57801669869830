import { inject, Injectable } from '@angular/core';
import { FetchCurrentUserUc } from './fetch-current-user.uc';
import { DomainException } from '../domain/entities/exceptions';
import { UsersRepositoryPort } from '../domain/ports/users.repository.port';

export class SignUpRequest {
  constructor(
    public readonly email: string,
    public readonly password: string,
  ) {}
}

export class SignUpResponse {
  constructor(
    public readonly success: boolean,
    public readonly error?: string,
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class SignUpUc {
  private readonly usersRepository = inject(UsersRepositoryPort);
  private readonly fetchCurrentUserUc = inject(FetchCurrentUserUc);

  async execute(request: SignUpRequest): Promise<SignUpResponse> {
    try {
      await this.usersRepository.signUp(request.email, request.password);
      await this.fetchCurrentUserUc.execute();

      return { success: true };
    } catch (e) {
      if (e instanceof DomainException) {
        return { success: false, error: e.message };
      } else {
        return { success: false, error: 'Oops, something went wrong. Please try again later' };
      }
    }
  }
}
