import { SecurityChallengeScope, SecurityChallengeChannel } from '../entities/security-challenge.entity';

export class SecurityChallengeVm {
  constructor(
    public readonly id: string,
    public readonly type: 'one-time-password',
    public readonly scope: SecurityChallengeScope,
    public readonly channel: SecurityChallengeChannel,
    public readonly channelIdentifier: string,
    public readonly expiresAt: Date,
    public readonly canResentAt: Date,
  ) {}
}
