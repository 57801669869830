import { inject, Injectable } from '@angular/core';
import { SecurityChallengeVm } from '../domain/view-models/security-challenge.vm';
import {
  SecurityChallengeScope,
  SecurityChallengeChannel,
  SecurityChallengeEntity,
} from '../domain/entities/security-challenge.entity';
import { SecurityChallengesRepositoryPort } from '../domain/ports/security-challenges.repository.port';

export class TakeSecurityChallengeRequest {
  constructor(
    public readonly scope: SecurityChallengeScope,
    public readonly channel: SecurityChallengeChannel,
    public readonly challengeIdentifier: string,
  ) {}
}

export class TakeSecurityChallengeResponse {
  constructor(private readonly challenge: SecurityChallengeEntity) {}

  toChallengeVm() {
    return new SecurityChallengeVm(
      this.challenge.id,
      this.challenge.type,
      this.challenge.scope,
      this.challenge.channel,
      this.challenge.channelIdentifier,
      this.challenge.expiresAt,
      this.challenge.canResentAt,
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class TakeSecurityChallengeUc {
  private readonly challengesRepository = inject(SecurityChallengesRepositoryPort);

  async execute(request: TakeSecurityChallengeRequest): Promise<TakeSecurityChallengeResponse> {
    const { scope, channel, challengeIdentifier: channelIdentifier } = request;
    const challenge = await this.challengesRepository.takeChallenge(scope, channel, channelIdentifier);

    return new TakeSecurityChallengeResponse(challenge);
  }
}
